<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card title="Talep Oluştur">
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <administrative-request-users-card />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <administrative-request-title />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <administrative-request-subject-card />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <administrative-request-status-code-card />
            </b-col>
            <!--            <b-col-->
            <!--              cols="12"-->
            <!--              md="6"-->
            <!--            >-->
            <!--              <administrative-request-s-date-card />-->
            <!--            </b-col>-->
            <!--            <b-col-->
            <!--              cols="12"-->
            <!--              md="6"-->
            <!--            >-->
            <!--              <administrative-request-e-date-card />-->
            <!--            </b-col>-->
            <b-col
              cols="12"
              md="12"
            >
              <administrative-request-content-card />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
// import AdministrativeRequestSDateCard from '@/views/Administrative/Elements/SdateCard.vue'
// import AdministrativeRequestEDateCard from '@/views/Administrative/Elements/EdateCard.vue'
import AdministrativeRequestContentCard from '@/views/Administrative/Elements/ContentCard.vue'
import AdministrativeRequestSubjectCard from '@/views/Administrative/Elements/SubjectCard.vue'
import AdministrativeRequestStatusCodeCard from '@/views/Administrative/Elements/StatusCodeCard.vue'
import AdministrativeRequestUsersCard from '@/views/Administrative/Elements/userCard.vue'
import AdministrativeRequestTitle from '@/views/Administrative/Elements/TitleCard.vue'

export default {
  name: 'AdministrativeRequestAddItemform',
  components: {
    AdministrativeRequestTitle,
    AdministrativeRequestUsersCard,
    AdministrativeRequestStatusCodeCard,
    AdministrativeRequestSubjectCard,
    AdministrativeRequestContentCard,
    // AdministrativeRequestEDateCard,
    // AdministrativeRequestSDateCard,
    BCard,
    BRow,
    BCol,
  },
  computed: {
    itemData() {
      return this.$store.getters['administrativeRequest/dataItem']
    },
  },
}
</script>
